<template>
    <div>
        <div class="form-row">
            <div class="form-col">
                <label>gallery one title</label>
                <text-input v-model="galleryOne.title" />
                <span v-if="hasError('galleryOne.title')" class="form-error">{{ showError('galleryOne.title') }}</span>

                <label class="mt-8">gallery one images</label>
                <file-manager
                    :main-directory="'cms'"
                    :library="getLibraryImageFilesByBaseDirectory(['marketing', 'cms'])"
                    :libraryFilters="['marketing', 'cms']"
                    :max-nb-of-files="10"
                    :max-upload-size="20"
                    :cropper-options="{aspectRatio: 1, cropBoxResizable: true, zoomOnWheel: false}"
                    :noCropper="true"
                    @uploaded-to-s3="createWebsiteImage"
                    v-model="galleryOne.images"
                >
                    <template v-slot:hint="{ canAddFiles, maxNbOfFiles, maxUploadSize }">
                        <span class="form-hint" v-if="canAddFiles">
                            Select at least {{ minNbImages }} images (up to {{ maxNbOfFiles }}).
                            The max upload file size is {{ maxUploadSize }}MB.
                        </span>
                    </template>
                </file-manager>
                <span class="form-hint" v-if="!galleryOne.images.length && !hasError('galleryOne.images')">
                    The first image in the gallery will be used as the featured image
                </span>
                <span v-if="hasError('galleryOne.images')" class="form-error">{{ showError('galleryOne.images') }}</span>
            </div>
            <div class="form-col">
                <label>gallery two title</label>
                <text-input v-model="galleryTwo.title" />
                <span v-if="hasError('galleryTwo.title')" class="form-error">{{ showError('galleryTwo.title') }}</span>

                <label class="mt-8">gallery two images</label>
                <file-manager
                    :main-directory="'cms'"
                    :library="getLibraryImageFilesByBaseDirectory(['marketing', 'cms'])"
                    :libraryFilters="['marketing', 'cms']"
                    :max-nb-of-files="10"
                    :max-upload-size="20"
                    :cropper-options="{aspectRatio: 1, cropBoxResizable: true, zoomOnWheel: false}"
                    :noCropper="true"
                    @uploaded-to-s3="createWebsiteImage"
                    v-model="galleryTwo.images"
                >
                    <template v-slot:hint="{ canAddFiles, maxNbOfFiles, maxUploadSize }">
                        <span class="form-hint" v-if="canAddFiles">
                            Select at least {{ minNbImages }} images (up to {{ maxNbOfFiles }}).
                            The max upload file size is {{ maxUploadSize }}MB.
                        </span>
                    </template>
                </file-manager>
                <span class="form-hint" v-if="!galleryTwo.images.length && !hasError('galleryTwo.images')">
                    The first image in the gallery will be used as the featured image
                </span>
                <span v-if="hasError('galleryTwo.images')" class="form-error">{{ showError('galleryTwo.images') }}</span>
            </div>
        </div>
        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import Widget from "@/mixins/Widget";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import ModalFooter from "@/components/ui/modals/ModalFooter";
    import FileManager from "@/components/ui/filemanager/FileManager";
    import TextInput from '@/components/ui/TextInput';

    export default {
        mixins: [ ModalNavigation, Widget ],
        components: { ModalFooter, FileManager, TextInput },
        emits: ['save-page-widget'],
        data: () => {
            return {
                galleryOne: {
                    images: [],
                    title: null
                },
                galleryTwo: {
                    images: [],
                    title: null
                },
                minNbImages: 5,
                isProcessing: false,
            }
        },
        computed: {
            ...mapGetters({
                getLibraryImageFilesByBaseDirectory: 'files/getLibraryImageFilesByBaseDirectory',
                getThumbnailUrlByFileId: 'files/getThumbnailUrlByFileId',
                getFileIdByThumbnailUrl: 'files/getFileIdByThumbnailUrl'
            })
        },
        methods: {
            save() {
                if(this.validator.passes()) {
                    this.isProcessing = true;
                    let galleryOne = [];
                    let galleryTwo = [];
                    for( let i = 0; i < this.galleryOne.images.length; i++){
                        galleryOne[i] = (this.getThumbnailUrlByFileId(this.galleryOne.images[i]));
                    }
                    for( let i = 0; i < this.galleryTwo.images.length; i++){
                        galleryTwo[i] = (this.getThumbnailUrlByFileId(this.galleryTwo.images[i]));
                    }
                    this.$emit('save-page-widget', {
                        id: Math.random(500),
                        galleryOneTitle: this.galleryOne.title,
                        galleryOneImages: JSON.stringify(galleryOne),
                        galleryTwoTitle: this.galleryTwo.title,
                        galleryTwoImages: JSON.stringify(galleryTwo),
                    });
                }
            },
        },
        validations: {
            'galleryOne.title' : 'required',
            'galleryOne.images' : 'required | min:minNbImages',
            'galleryTwo.title' : 'required',
            'galleryTwo.images' : 'required | min:minNbImages',
        },
        created() {
            this.initValidator();
            this.validator.setCustomErrorMessages({
                'galleryOne.images.min' : 'You must select at least 5 images.',
                'galleryTwo.images.min' : 'You must select at least 5 images.',
            })
            // Load values when editing the widget
            if (this.values.length) {
                let galleryOne = [];
                let galleryTwo = [];
                for( let i = 0; i < JSON.parse(this.getValue('galleryOneImages')).length; i++){
                    galleryOne[i] = (this.getFileIdByThumbnailUrl(JSON.parse(this.getValue('galleryOneImages'))[i]));
                }
                for( let i = 0; i < JSON.parse(this.getValue('galleryTwoImages')).length; i++){
                    galleryTwo[i] = (this.getFileIdByThumbnailUrl(JSON.parse(this.getValue('galleryTwoImages'))[i]));
                }
                this.galleryOne.images = JSON.stringify(galleryOne) ? galleryOne : [];
                this.galleryTwo.images = JSON.stringify(galleryTwo) ? galleryTwo: [];
                this.galleryOne.title = this.getValue('galleryOneTitle');
                this.galleryTwo.title = this.getValue('galleryTwoTitle');
            }
        }
    }
</script>
